@font-face {
	font-family: 'Basier Circle';
	src: url('Basier/BasierCircle-Regular.eot');
	src: url('Basier/BasierCircle-Regular.eot?#iefix') format('embedded-opentype'),
	url('Basier/BasierCircle-Regular.woff2') format('woff2'),
	url('Basier/BasierCircle-Regular.woff') format('woff'),
	url('Basier/BasierCircle-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Basier Circle';
	src: url('Basier/BasierCircle-Italic.eot');
	src: url('Basier/BasierCircle-Italic.eot?#iefix') format('embedded-opentype'),
	url('Basier/BasierCircle-Italic.woff2') format('woff2'),
	url('Basier/BasierCircle-Italic.woff') format('woff'),
	url('Basier/BasierCircle-Italic.ttf') format('truetype');
	font-weight: 400;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Basier Circle';
	src: url('Basier/BasierCircle-Medium.eot');
	src: url('Basier/BasierCircle-Medium.eot?#iefix') format('embedded-opentype'),
	url('Basier/BasierCircle-Medium.woff2') format('woff2'),
	url('Basier/BasierCircle-Medium.woff') format('woff'),
	url('Basier/BasierCircle-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Basier Circle';
	src: url('Basier/BasierCircle-MediumItalic.eot');
	src: url('Basier/BasierCircle-MediumItalic.eot?#iefix') format('embedded-opentype'),
	url('Basier/BasierCircle-MediumItalic.woff2') format('woff2'),
	url('Basier/BasierCircle-MediumItalic.woff') format('woff'),
	url('Basier/BasierCircle-MediumItalic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Basier Circle';
	src: url('Basier/BasierCircle-SemiBold.eot');
	src: url('Basier/BasierCircle-SemiBold.eot?#iefix') format('embedded-opentype'),
	url('Basier/BasierCircle-SemiBold.woff2') format('woff2'),
	url('Basier/BasierCircle-SemiBold.woff') format('woff'),
	url('Basier/BasierCircle-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Basier Circle';
	src: url('Basier/BasierCircle-SemiBoldItalic.eot');
	src: url('Basier/BasierCircle-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
	url('Basier/BasierCircle-SemiBoldItalic.woff2') format('woff2'),
	url('Basier/BasierCircle-SemiBoldItalic.woff') format('woff'),
	url('Basier/BasierCircle-SemiBoldItalic.ttf') format('truetype');
	font-weight: 600;
	font-style: italic;
	font-display: swap;
}

@font-face {
	font-family: 'Basier Circle';
	src: url('Basier/BasierCircle-Bold.eot');
	src: url('Basier/BasierCircle-Bold.eot?#iefix') format('embedded-opentype'),
	url('Basier/BasierCircle-Bold.woff2') format('woff2'),
	url('Basier/BasierCircle-Bold.woff') format('woff'),
	url('Basier/BasierCircle-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Basier Circle';
	src: url('Basier/BasierCircle-BoldItalic.eot');
	src: url('Basier/BasierCircle-BoldItalic.eot?#iefix') format('embedded-opentype'),
	url('Basier/BasierCircle-BoldItalic.woff2') format('woff2'),
	url('Basier/BasierCircle-BoldItalic.woff') format('woff'),
	url('Basier/BasierCircle-BoldItalic.ttf') format('truetype');
	font-weight: 700;
	font-style: italic;
	font-display: swap;
}
